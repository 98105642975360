import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import moment from "moment";
import Layout from "../components/layout";

import { Card } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import CategorySelection from "../components/categories-section";
import ServiceHero from "../components/service-hero";

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "blog" }) {
        escBlogsFields {
          ecfBlogsBanner {
            ecfBlogsBannerHeading
            ecfBlogsBannerBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 30
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
      allWpCategory(filter: { count: { ne: 0 } }) {
        nodes {
          name
          slug
        }
      }
      allWpPost(
        sort: { fields: dateGmt, order: DESC }
        filter: { title: { ne: "Website Images" } }
      ) {
        nodes {
          slug
          excerpt
          title
          dateGmt
          blogFields {
            description
            metaDescription
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
                altText
              }
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFields, escBlogsFields },
  } = data;

  const { ecfBlogsBanner } = escBlogsFields;

  const categories = data.allWpCategory.nodes;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog",
        item: {
          url: `${siteUrl}/blog`,
          id: `${siteUrl}/blog`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />
      <div style={{ overflow: "hidden" }}>
        {ecfBlogsBanner && (
          <section>
            <ServiceHero
              title={ecfBlogsBanner.ecfBlogsBannerHeading}
              backgroundImage={
                ecfBlogsBanner.ecfBlogsBannerBackgroundImage?.node?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt="ECF Blog posts page"
            />
          </section>
        )}

        <CategorySelection
          categories={categories}
          currentCategorySlug={null} // This would be provided by your page's context in Gatsby
        />

        <section className=" pb-5 pb-lg-7 ">
          <Container>
            <Row className=" h-100 gy-3 gy-lg-5">
              {data.allWpPost.nodes.map((post) => (
                <Col
                  style={{ minHeight: "100%", borderRadius: "10px" }}
                  className=""
                  md={6}
                  lg={4}
                >
                  <Card style={{ border: "0px" }} className="h-100 ">
                    <Card.Img
                      as={GatsbyImage}
                      variant="top"
                      style={{ height: "225px" }}
                      image={
                        post.blogFields.featuredImage.node.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={post.blogFields.featuredImage.node.altText}
                    />
                    <Card.Body className="p-3 bg-grey position-relative pb-5">
                      <Card.Title className="text-black pe-5">
                        {post.title}
                      </Card.Title>
                      <Card.Text>{post.blogFields.description}</Card.Text>
                      <div className="position-absolute bottom-0 start-0 p-3 ">
                        <Link
                          to={`/blog/${post.slug}`}
                          className="black-link inter-bold"
                        >
                          Read More
                        </Link>
                      </div>
                      <div className="top-0 end-0  position-absolute translate-middle-y pe-3">
                        <div
                          style={{
                            borderRadius: "100%",
                            height: "50px",
                            width: "50px",
                          }}
                          className="bg-white text-center d-flex align-items-center justify-content-center"
                        >
                          <p
                            style={{ fontSize: "85%", lineHeight: "105%" }}
                            className="p-0 m-0 "
                          >
                            <span className="inter-bold pb-0 mb-0">
                              {moment(post.dateGmt).format("DD").toUpperCase()}
                            </span>
                            <br />
                            {moment(post.dateGmt).format("MMM").toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

export default BlogPage;
